import { server } from "../Data/Server";

export const getUser = async (userId) => {
    let res = await fetch (`${server.OurUrl}/api/Candidates/${userId}`)
    let json = await res.json()
    return json
}

export const getQuestionnaire = async (qId) => {
    let res = await fetch(`${server.OurUrl}/api/AssessmentQuestionnaires/${qId}`)
    let json = await res.json()
    return json
}

export const getCandidateResults = async (candidateId, qId) => {
    try {
        let res = await fetch(`${server.OurUrl}/api/Candidates/${candidateId}/results/${qId}`)
        let json = await res.json()
        return json    
    } catch (error) {
        return null
    }    
}

export const postCandidateResults = async (candidateId, qId) => {
    await fetch(`${server.OurUrl}/api/CandidateResults`, {  
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({ candidateId: candidateId, assessmentId: qId})
    })
}

export const gradeCandidateAssessment = async (cId, qId,countOfMouse,exitedFullScreen, ans) => {
    await fetch(`${server.OurUrl}/api/AssessmentQuestionnaires/${qId}/${cId}/${countOfMouse}/${exitedFullScreen}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(ans)
    })
}

export const startCandidateAssessment = async (cId, qId) => {
    await fetch(`${server.OurUrl}/api/Candidates/${cId}/startAssessment/${qId}`)
}
