import React, { useEffect, useState } from 'react'
import Questionnaire from '../components/Questionnaire/Questionnaire';
import StartTest from '../components/StartTest/StartTest';
import { useNavigate } from 'react-router-dom';
import ResultBox from '../components/Result/ResultBox';
import { useParams } from "react-router-dom";
import { getQuestionnaire, getUser, getCandidateResults } from '../Helpers/AsyncCalls'

const Home = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setloading] = useState(true)
  const [user, setUser] = useState();
  const [questionnaire, setQuestionnaire] = useState();
  const [questionnaireData, setQuestionnaireData] = useState();
  const [isStarted, setIsStarted] = useState(false);
  const [candidateResults, setCandidateResults] = useState();

  useEffect(() => {
    if (Object.keys(params).length !== 2) {
      navigate('/404')
    }

    if (user === undefined) {
      getUser(params.userId).then(data => setUser(data))
    }
    
    if (questionnaire === undefined) {
      getQuestionnaire(params.questionnaireId).then((data) => { setQuestionnaire(data); setQuestionnaireData(JSON.parse(data.assessmentData))})
    }

    if (candidateResults === undefined)
    {
      getCandidateResults(params.userId, params.questionnaireId).then((data) => setCandidateResults(data))
    }

    if (user !== undefined && questionnaire !== undefined && candidateResults !== undefined) {
      setloading(false)
    }
    
  }, [user, questionnaire, navigate, params, candidateResults])

  return (
    <div>
      {
        candidateResults === undefined || candidateResults === null || candidateResults.score === null
        ?
        isStarted
          ?
          <Questionnaire user={user} questionnaire={questionnaire} questionnaireData={questionnaireData} setCandidateResults={setCandidateResults}/>
          :
          <StartTest user={user} questionnaire={questionnaire} setIsStarted={setIsStarted} loading={loading}/>
        :
        <ResultBox user={user} score={candidateResults?.score} totalQuestions={questionnaireData?.length}/>
      }
    </div>
  )
}

export default Home
