import { Button, Checkbox, Container, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material'
import React, { useEffect, useState } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './Questionnaire.css'
import Timer from '../Utils/Timer';
import { gradeCandidateAssessment, getCandidateResults } from '../../Helpers/AsyncCalls';
import LoadingButton from '@mui/lab/LoadingButton';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Questionnaire = ({ user, questionnaire, questionnaireData, setCandidateResults }) => {

    const [questionNo, setQuestionNo] = useState(0);
    const [loading, setloading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    //tmak to capture out of screen 
    const [cursorOutOfScreenCount, setCursorOutOfScreenCount] = useState(0);
    //tmak to force full screen
    const [isFullScreen, setIsFullScreen] = useState(false);
    
    //tmak to force full screen
    useEffect(() => {
        
        const element = document.documentElement; // Fullscreen the entire page
    
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }, []); // Empty dependency array to run this effect only once, when the component mounts
    

      useEffect(() => {
        const handleFullScreenChange = () => {
          const newIsFullScreen = !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement);
          if (!newIsFullScreen && isFullScreen) {
            
          }
          setIsFullScreen(newIsFullScreen);
        };
    
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);
    
        return () => {
          document.removeEventListener('fullscreenchange', handleFullScreenChange);
          document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
          document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
          document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
      }, [isFullScreen]);

    useEffect(() => {
        setSelectedOptions(new Array(questionnaireData.length).fill(-1))
    }, [questionnaireData])

    //tmak to capture out of screen 
    useEffect(() => {
        const handleMouseLeave = (e) => {
          if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
            handleCursorLeave();
          }
        };
      
        document.addEventListener('mouseleave', handleMouseLeave);
      
        return () => {
          // Clean up the event listener when the component unmounts.
          document.removeEventListener('mouseleave', handleMouseLeave);
        };
      }, [cursorOutOfScreenCount]); // Add cursorOutOfScreenCount to dependencies to prevent memory leaks.

    const handleToggle = (value) => {
        let optionSelection = [...selectedOptions];
        optionSelection[questionNo] = value;

        setSelectedOptions(optionSelection);
    }

    //tmak to capture out of screen
    const handleCursorLeave = () => {
        setCursorOutOfScreenCount(cursorOutOfScreenCount + 1);
        //console.log("cursor left");
      };

    const handleNext = async () => {        
        if ((questionNo + 1) < questionnaireData.length) {
            setQuestionNo(questionNo + 1)
        }
        else {
            // Submitting the test
            setloading(true)
            await gradeCandidateAssessment(user.id, questionnaire.id,cursorOutOfScreenCount,!isFullScreen,selectedOptions);
            let results = await getCandidateResults(user.id, questionnaire.id);
            setCandidateResults(results);
        }
    }

    const handlePrevious = () => {
        setQuestionNo(questionNo - 1)
    }

    function isLastQuestion() {
        return questionNo === questionnaireData.length - 1
    }

    return (
        <div className='h-screen w-screen'>
            <header className='bg-white mt-4'>
                <div className="nextBtn p-4 flex justify-between">
                        <Button disabled={!questionNo || loading} onClick={handlePrevious} variant="contained" size="medium" className='w-36 h-12 self-center' startIcon={<><NavigateBeforeIcon /></>}>
                            Previous
                        </Button>
                        <div className='flex flex-col w-full justify-center'>
                            <h3 className='text-2xl self-center font-semibold md:text-2xl '>{questionnaire?.title}</h3>
                            <div className=''>
                                <span className='text-sm text-blue-500 roboto'><b><span>Question</span>: {questionNo + 1}</b> of <b>{questionnaireData.length}</b></span>
                                <Timer min={questionnaire?.time} hours={0} />
                            </div>   
                        </div>                                          
                        <LoadingButton
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            onClick={handleNext}
                            variant="contained"
                            size="medium"
                            className={`w-36 h-12 self-center`}
                            endIcon={!loading && (!isLastQuestion() ? <NavigateNextIcon /> : <ExitToAppIcon />)}>
                            {
                                !isLastQuestion() ? 'Next' : 'End Test'
                            }
                        </LoadingButton>
                    </div>
            </header>
            <div className="mt-4 shadow-md rounded-sm bg-white overflow-scroll h-4/5 ">                    
                    {
                        questionnaireData?.map((q, i) => {
                            return (
                                <div key={i} className={`${i !== questionNo && 'hidden'}`}>
                                    <div className='flex flex-row space-x-12'>
                                        <div className="disable-copy-paste w-1/2 my-4 ml-4" onContextMenu={(e) => e.preventDefault()}>
                                            <h4 className='text-xl self-center font-semibold mb-4'>Question</h4>
                                            <Markdown className='text-l font-medium text-left text-gray-600' 
                                                components={{
                                                    img:({node,...props})=><img className='object-scale-down h-96'{...props}/>
                                                }}>
                                                    {q.question.replace("<br>", "\n")}
                                            </Markdown>
                                        </div>
                                        <div className="inline-block h-screen min-h-[1em] w-0.5 self-stretch bg-neutral-200 opacity-100 dark:opacity-50"></div>
                                        <div className="flex flex-col gap-1 w-1/2 my-4">
                                            <h4 className='text-xl self-center font-semibold mb-4'>Answers</h4>
                                            {
                                                q.answers.map((opt, i) =>
                                                    <ListItem
                                                        className='bg-slate-100'
                                                        key={i}
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="comments">

                                                            </IconButton>
                                                        }
                                                        disablePadding>
                                                        <ListItemButton disabled={loading} role={undefined} dense onClick={() => handleToggle(i)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={(selectedOptions[questionNo] === i )}
                                                                    tabIndex={-1}
                                                                    inputProps={{ 'aria-labelledby': opt }}
                                                                />
                                                            </ListItemIcon>
                                                            <Markdown className='text-l my-3 font-medium text-left text-gray-600'>{opt}</Markdown>
                                                        </ListItemButton>
                                                    </ListItem>)
                                            }
                                        </div>
                                    </div>                           
                                    
                                </div>
                            )
                        })
                    }
                    
                </div>
        </div>
    )
}

export default Questionnaire
