import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import './QuizApp.css'
import PageNotFound from './pages/404Page'

const WebFormApp = () => {
    return (
        <>
            <Routes>
                <Route exact path='/:questionnaireId/:userId' element={<Home/>} />
                <Route path="/404" element={<PageNotFound />} />
          	    <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </>
    )
}

export default WebFormApp
